import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BarsOutlined, TableOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Col, Divider, message, Row, Space, Switch, Tooltip } from 'antd';
import { useTranslation } from "react-i18next";
import {
    Project,
    ProjectChangePriorityAndStatusMutation,
    ProjectMemberInvite,
    Tag,
    useProjectChangePriorityAndStatusMutation,
    UsersUnion
} from "../../generated-types";
import ProjectsGroup from "../Project/ProjectsGroup";
import ProcessProjectInvite from "./ProcessProjectInvite";
import ProjectsListCard, { droppedOnProject } from "../Project/ProjectsListCard";
import { useDrop } from "react-dnd";
import ProjectNew from "../Project/ProjectNew";
import { Link } from "react-router-dom";
import TooltipButton from "../TooltipButton";
import Tagger from "../Tagger";
import client from "../../ApolloClient";
import GeneralBoard from '../GeneralBoard/GeneralBoard';
import { GeneralBoardItemType } from '../GeneralBoard/GeneralBoardCard';
import { GeneralBoardColumnType } from '../GeneralBoard/GeneralBoardColumn';
import { t } from 'i18next';


enum DisplayView {
    list,
    board
}


export interface UsersUnionProjectsListProps {
    union: UsersUnion;
    tags?: string[];
    onChange?: (checked: boolean) => void;
}

// TODO: настройки конкретного отображения задач надо сохранять на сервере для конкретного пользователя и оттуда же считывать
const getDefaultProjectsDisplayParams = (id: String): UUDisplayParams => {
    const txt = localStorage.getItem(`uu${id}`);

    let res = {} as UUDisplayParams;

    if (txt) {
        const saved = JSON.parse(txt);
        if (saved) {
            res = saved as UUDisplayParams
        }
    }
    if (!res.displayView)
        res.displayView = DisplayView.board;

    return res
}

const saveProjectsDisplayParams = (id: String, p: UUDisplayParams) => {
    const s = JSON.stringify(p);
    localStorage.setItem(`uu${id}`, s);
}

interface UUDisplayParams {
    displayView: DisplayView,
    active: boolean,
    selectedTags: string[],
}

const UsersUnionProjectsBoard: React.FC<UsersUnionProjectsListProps> = ({ union, tags }) => {
    const statuses = ["new", "frozen", "active", "finished"];
    const [drop, setDrop]=useState<[Project | undefined, Project | undefined, string | undefined]>([undefined, undefined, undefined])
    const [inviteInfo, setInviteInfo] = useState<ProjectMemberInvite | null>(null);
    const [moveProject] = useProjectChangePriorityAndStatusMutation({
        optimisticResponse: ({ projectId, status, priority }) => {
            const res = {
                projectChangePriorityAndStatus: {
                    id: projectId,
                    priority,
                    status
                }
            } as ProjectChangePriorityAndStatusMutation;
            return res;
        }
    })

    const byPriority = (p1: Project, p2: Project) => {
        if (p1.priority != p2.priority) return p1.priority < p2.priority ? 1 : -1
        return p1.id < p2.id ? 1 : -1
    };

    const hasTags = tags && tags.length > 0;

    const ownedProjects: Project[] = union.ownedProjects.filter(project=>hasTags ? project.attachedTags.some((projectTag)=>tags.some(tag=>tag == projectTag)) : true);
    const invites: ProjectMemberInvite[] = union.projectInvites.filter(invite=>hasTags ? invite.project.attachedTags.some((projectTag)=>tags.some(tag=>tag == projectTag)) : true)
    const allProjects = (ownedProjects.concat(invites.map(invite => invite.project)).sort(byPriority))
    
    useEffect(()=>{
        
        const [droppedProject, droppedOnProject, col]=drop
        if(!droppedProject) return

        const column=(droppedOnProject? droppedOnProject.status : col) as string

        const columnProjects=allProjects.filter(value=>value.status===column)

        let underPosition = droppedOnProject?.priority ?? 0
        const ps = columnProjects.filter(v => v.priority >= underPosition && v.id != droppedOnProject?.id).sort(byPriority);
        const upperProject = ps.pop();

        if (!underPosition && !upperProject?.priority) underPosition = 1000000;

        const upperPosition = upperProject?.priority ?? underPosition * 3
        const priority: number = underPosition + (upperPosition - underPosition) / 2;

        client.cache.modify({
            fields: {
                priority() { return priority },
                status() { return column }
            },
            id: client.cache.identify(droppedProject)
        })
        

        moveProject({
            variables: {
                projectId: droppedProject.id,
                usersUnionId: union.id,
                priority,
                status: column
            },
        })


    }, [drop])

    const boardProjects = allProjects.map((value) => ({ id: value.id, columnId: value.status, item: value } as GeneralBoardItemType<Project>))
    
    const boardColumns = statuses.map((status) => ({ id: status, title: t(`project.status.${status}`), item: status }) as GeneralBoardColumnType<string>)

    const projectsBoardColumnSplitter = (column: GeneralBoardColumnType<string>, items: GeneralBoardItemType<Project>[]) => {
        return items.filter(item => item.columnId === column.id)
    }

    const onProjectRender = (project: Project) => {
        const ou = project.userAsOwner ?? project.usersUnionAsOwner;
        const ownerTitle = project.userAsOwner?.fullName
            ?? project.userAsOwner?.username ?? project.usersUnionAsOwner?.title;
        const i = invites.find(v => v.project.id == project.id) ?? null;

        return <ProjectsListCard key={project.id + '-' + project.status + '-' + project.priority} project={project} showStatus={false} showButtons={true}
            ownerInfo={ou && <div style={{ display: "flex" }}>
                <Breadcrumb style={{ flex: 1 }} items={[{
                    title: <Link to={`/${ou?.__typename}/${ou?.id}`}>{ownerTitle}</Link>
                }]} rootClassName={"projectBreadcrumbLink"} />
                <TooltipButton
                    tooltipProps={{
                        title: t("usersUnion.projectInvitesManage")
                    }}
                    type={"link"} onClick={(event) => { event.stopPropagation(); setInviteInfo(i);}}
                ><UserSwitchOutlined /></TooltipButton>
            </div>}
            showFavorite={true}></ProjectsListCard>
    }

    const onProjectDropped =(droppedProject: Project, droppedOnProject?: Project, column?: string) => {
        setDrop([droppedProject, droppedOnProject, column])
    }

    return <div style={{ height: "calc(100vh - 150px)" }}>
        {inviteInfo && <ProcessProjectInvite key={inviteInfo?.id} invite={inviteInfo} union={union} onClose={() => setInviteInfo(null)} />}
        <GeneralBoard
            signature='PROJECT'
            items={boardProjects}
            columns={boardColumns}
            columnsSplitter={projectsBoardColumnSplitter}
            onItemRender={onProjectRender}
            onItemDropped={onProjectDropped}
        />
    </div>
}

const UsersUnionProjectsList: React.FC<UsersUnionProjectsListProps> = ({ union, onChange }) => {
    const { t } = useTranslation();
    const [displayParams, setDisplayParams] = useState<UUDisplayParams>({} as UUDisplayParams);
    const [inviteInfo, setInviteInfo] = useState<ProjectMemberInvite | null>(null);

    useEffect(() => {
        // TODO: надо разобраться, как не вызывать сохранение сразу после загрузки из локалстореджа
        if (displayParams.displayView === undefined) {
            setDisplayParams(getDefaultProjectsDisplayParams(union.id))
        } else
            saveProjectsDisplayParams(union.id, displayParams)
    }, [displayParams]);

    const availableProjTags: Tag[] = useMemo(() => {
        const ownedProj = union.ownedProjects.map((p) => p);
        const inviteProj = union.projectInvites.map((p) => p.project);
        const tags = [...ownedProj, ...inviteProj]
            .map((project) => project.tags.concat(union.tags).map(t => ({ ...t, project: project } as unknown as Tag)))
            .flat()
            .filter((i) => i.project.attachedTags.some(t => t.includes(i.id)))

        return tags
            .map(v => v.id)
            .reduce((res: string[], v: string) => {
                if (res.indexOf(v) == -1) res.push(v);
                return res;
            }, [])
            .map(v => tags.find((vv: Tag) => vv.id == v)) as Tag[]
            ;
    }, [union.ownedProjects, union.projectInvites])

    const unionProjectInvites = useMemo(() => {
        if (displayParams.selectedTags?.length) {
            return union.projectInvites.filter((p) => p.project.attachedTags.some((t) => displayParams.selectedTags.some(tagId => tagId == t)))
        } else return union.projectInvites
    }, [displayParams])

    const unionOwnedProjects = useMemo(() => {
        if (displayParams.selectedTags?.length) {
            return union.ownedProjects.filter((p) => p.attachedTags.some((t) => displayParams.selectedTags.some(tagId => tagId == t)))
        } else return union.ownedProjects
    }, [displayParams])

    return <>
        <Row style={{ padding: 10 }} justify="space-around" align="middle">
            <Col sm={12} md={6} >
                <Space.Compact block>
                    <Tooltip title={t('task.tasksAsBoardTooltip')}>
                        <Button icon={<TableOutlined rev={undefined} />}
                            ghost={displayParams.displayView == DisplayView.board}
                            type={displayParams.displayView == DisplayView.board ? "primary" : "default"}
                            onClick={() => {
                                setDisplayParams({ ...displayParams, displayView: DisplayView.board })
                            }}
                        >{t('task.tasksAsBoard')}</Button>
                    </Tooltip>
                    <Tooltip title={t('task.tasksAsListTooltip')}>
                        <Button icon={<BarsOutlined rev={undefined} />}
                            ghost={displayParams.displayView == DisplayView.list}
                            type={displayParams.displayView == DisplayView.list ? "primary" : "default"}
                            onClick={() => {
                                setDisplayParams({ ...displayParams, displayView: DisplayView.list })
                            }}
                        >{t('task.tasksAsList')}</Button>
                    </Tooltip>
                </Space.Compact>
            </Col>
            <Col sm={12} md={6} >
                <Tagger
                    key={union.id}
                    allowEditProjectTags={false} tags={availableProjTags} editable={true} block={true}
                    placeholder={t('task.tasksViewFilterByTags') as string}
                    defaultValue={displayParams.selectedTags}
                    onChanged={(tags) => {
                        setDisplayParams({ ...displayParams, selectedTags: tags })
                    }}
                />
            </Col>
            <Col sm={12} md={6}>
                <Switch
                    onChange={(checked: boolean) => onChange ? onChange(checked) : null}
                    defaultChecked={true}
                    checkedChildren={t("project.activeProjects")}
                    unCheckedChildren={t("project.archivedProjects")}
                />
            </Col>
            <Col sm={12} md={6} >
                <ProjectNew isBlocked={union.paymentAccount?.isBlocked} context={union} />
            </Col>
        </Row>

        {displayParams.displayView == DisplayView.list
            ? <span>
                <ProjectsGroup projects={unionOwnedProjects} context={union.currentUserIsMember ? union : undefined} />
                {union.projectInvites.length > 0 && <>
                    {inviteInfo && <ProcessProjectInvite key={inviteInfo?.id} invite={inviteInfo} union={union} onClose={() => setInviteInfo(null)} />}
                    <Divider>{t('usersUnion.projectInvites')}</Divider>
                    <Row gutter={[16, 16]}>{
                        unionProjectInvites.map((inv: ProjectMemberInvite) => {
                            const { project } = inv;
                            return <Col key={inv.project.id} flex="300px">
                                <ProjectsListCard key={project.id} project={project}
                                    showButtons={false} showFavorite={true}
                                    onProjectClick={() => {
                                        setInviteInfo(inv)
                                    }}
                                /></Col>;
                        }
                        )}</Row></>
                }
            </span>
            : <>
                <UsersUnionProjectsBoard union={union} tags={displayParams.selectedTags} />
            </>
        }
    </>
};

export default UsersUnionProjectsList;
