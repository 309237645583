import React, {useEffect, useState} from 'react'
import {BlockOutlined, DeleteOutlined, FileOutlined} from '@ant-design/icons';
import {Button, Col, Form, FormInstance, Input, List, notification,} from 'antd';
import {
    AttachedFolder,
    EditTaskInput,
    ExternalFile,
    ExternalFileType,
    Project,
    Task,
    TaskMember,
    TaskMemberInput,
    TaskStatus,
} from "../../generated-types";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import TaskMembersEditor from "./TaskMembersEditor";
import AttachFilesButton from "../File/AttachFilesButton";
import Tagger from "../Tagger";
import {allUsersUnions} from "../../subscriptions/allUsersUnions";
import {allProjects} from "../../subscriptions/allProjects";
import {useReactiveVar} from '@apollo/client';
import dayjs from 'dayjs'
import TaskImportance from "./TaskImportance";
import DownloadFileButton from "../File/DownloadFileButton";
import TaskCheckList from "./TaskCheckList";
import {CardRowStyle} from "./TaskCard.styled";
import TaskDates from "./TaskDates";

const {TextArea} = Input;

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 24},
};

interface TaskCardProps {
    task: Task,
    project: Project,
    projectId: string | undefined,
    save: (values: any, members: TaskMember[], files: ExternalFile[], tags: string[]) => void,
    form: FormInstance
}

export const valuesToTaskInput = (values: any, members: TaskMember[] | undefined, files: ExternalFile[] | undefined, tags: string[] | undefined): EditTaskInput => {
    const {title, description, startTime, startDate, endTime, endDate, importance} = values;
    const res = {
        title, description,
        startDate,
        importance,
        endDate,
        startTime: startTime ? startTime : undefined,
        endTime: endTime ? endTime : undefined,
        tags: tags ?? [],
        members: members?.map(m => ({userId: m.user.id, memberType: m.memberType})) ?? [] as TaskMemberInput[],
        files: files?.map(f => ({externalFilePath: f.path, name: f.name})) ?? []
    } as EditTaskInput;
    // Для обратной совместимости. Статус задачи вообще нужно убрать
    res.status = TaskStatus.Todo;

    return res;
}

export const prepareTask = (task: Task): any => {
    const {startTime, endTime, endDate, startDate, ...tf} = task;
    return {
        startTime: startTime ? dayjs().startOf("day").add(startTime, "minutes") : undefined,
        endTime: endTime ? dayjs().startOf("day").add(endTime, "minutes") : undefined,
        startDate: startDate ? dayjs(startDate) : undefined,
        endDate: endDate ? dayjs(endDate) : undefined,
        ...tf
    } as any;
}

export type ExternalFileWithId = ExternalFile & {
    id: string
}


const TaskCard: React.FC<TaskCardProps> = ({task, form, save, project}) => {
    useNavigate();
    const {t} = useTranslation();
    const [members, setMembers] = useState<TaskMember[]>(task.members)
    const [tags, setTags] = useState<string[]>(task.tags)
    const [selectedFiles, setSelectedFiles] = useState<ExternalFileWithId[]>(
        task.files.map(v => ({name: v.name, path: v.path, type: ExternalFileType.File, id: v.id} as ExternalFileWithId))
    );

    //Обаботка события успешного submit
    const onFinish=(values: any)=>{
        save(values, members, selectedFiles, tags)
    }

    //Обработка ошибочного submit
    const onFinishFailed=(errorInfo: any)=>{
        errorInfo?.errorFields.forEach((errorField: any)=>{
            errorField?.errors.forEach((error: any)=>notification.error({message: error}))
        })
    }

    useEffect(() => {
        const keydownHandler = (e: any) => {
            if (e.keyCode === 13 && e.ctrlKey) {
                form.submit()
            }
        };

        document.addEventListener('keydown', keydownHandler);

        return () => {
            document.removeEventListener('keydown', keydownHandler);
        };
    }, [form]);

    const myUnions = useReactiveVar(allUsersUnions);
    const allP = useReactiveVar(allProjects);

    let folders: AttachedFolder[] = [];

    let p = allP.projects.find(pp => pp.id === project.id)
    let uu = myUnions.unions.find(uu => uu.id === project.usersUnionAsOwner?.id)
    if (p?.attachedFolders)
        folders = p.attachedFolders ?? []
    if (uu?.attachedFolders)
        folders = folders.concat(uu.attachedFolders);

    // const isAdmin = p?.members.some((m: ProjectMember)=>m.user.id === authInfo.user.id && m.accessLevel === AccessLevel.Admin) ?? false

    return (
        <Form
            layout="vertical"
            name="TaskCardForm"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            {...formItemLayout}
            initialValues={task}
        >
            <CardRowStyle>
                <Col xs={2}>
                    <Form.Item name="importance" noStyle={false}>

                        <TaskImportance value={task.importance} id={task.id} onChange={(importance) => {
                            form.setFieldValue("importance", importance);
                        }}/>
                    </Form.Item>
                </Col>
                <Col xs={22}>
                    <Form.Item name="title" noStyle={false} style={{marginBottom: 5}}
                               rules={[{required: true, message: "" + t('task.titleRequired')}]}>
                        <Input placeholder={t('task.title') as string} size={"large"}/>
                    </Form.Item>
                </Col>
                <Col xs={24}>

                    <Form.Item name="description" noStyle={false} style={{marginBottom: 5}}>
                        <TextArea rows={4} placeholder={"" + t('task.description')}/>
                    </Form.Item>

                </Col>
            </CardRowStyle>

            <CardRowStyle>
                <Tagger block editable={true} projectId={project.id} defaultValue={task.tags} allowEditProjectTags={true}
                        allowEditUsersUnionTags={true}
                        onChanged={(tags: string[]) => {
                            setTags(tags)
                        }}/>
            </CardRowStyle>

            <CardRowStyle>
                <TaskDates task={task} form={form}/>
            </CardRowStyle>

            <CardRowStyle>
                <TaskMembersEditor task={task} project={project} onUpdateMembers={(newMembers: TaskMember[]) => {
                    setMembers(newMembers)
                }}/>
            </CardRowStyle>

            <CardRowStyle>
                {folders.length > 0
                    ? <div className={"header"}>
                    <span style={{flex: 1, textAlign: "left", color: "gray"}}>
                      <FileOutlined style={{paddingRight: 5}}/>
                        {`${t('task.files')} (${selectedFiles.length})`}
                    </span>
	                <AttachFilesButton folders={folders} selectable={true}
	                                   onChange={(files: ExternalFile[]) => {
                                         setSelectedFiles(files as ExternalFileWithId[])
                                     }}
                                       selectedItems={selectedFiles} multipleSelect={true}
                                       btnProps={{
                                           type: "link", size: "small", color: "primary",
                                           style: {height: "100%"}
                                       }}
                    />
                    </div>
                    : <div className={"header"}>
                    <span style={{flex: 1, textAlign: "left", color: "gray"}}>
                      <FileOutlined style={{paddingRight: 5}}/>
                        {`${t('task.filesConnectDisk')}`}
                    </span>
                    </div>
                }

            {selectedFiles.length > 0 &&
                <List size={"small"} dataSource={selectedFiles} renderItem={(item) => {
                    return (item &&
                        <List.Item
                            actions={[
                                <Button type="text" danger={true} icon={<DeleteOutlined />}
                                onClick={() => {
                                    setSelectedFiles(selectedFiles.filter(f => f.path !== item.path))
                                }} />
                            ]}
                        >
                            <List.Item.Meta
                                // avatar={<LinkOutlined rev={undefined} />}
                                // avatar={<Avatar style={{marginLeft: 5}} src={item.user.avatarUrl} />}
                                // title={item.name}
                                // description={item.path}
                            />
                            <DownloadFileButton file={item}/>
                            {item.name}
                        </List.Item>
                    )
                }}>
                </List>}
            </CardRowStyle>

            <CardRowStyle>
                {task?.id
                    ? <TaskCheckList parentTask={task} project={project} childTasks={task.childTasks as Task[] ?? []}/>
                    : <div className={"header"}>
                    <span style={{flex: 1, textAlign: "left", color: "gray"}}>
                      <BlockOutlined style={{paddingRight: 5}}/>
                        {`${t('task.checkListPrompt')}`}
                    </span>
                    </div>
                }
            </CardRowStyle>
        </Form>)
}

export default TaskCard
